import { t, ToastContext, useWebAuth } from '@walter/shared-web'
import React, { useContext, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import AuthContext from './Auth'

const SessionValidatorContext = React.createContext({})

export default SessionValidatorContext

export function SessionValidatorProvider({ children }: { children: React.ReactNode }) {
  const { pathname: currentPath } = useLocation()
  const { isSessionExpired } = useWebAuth()
  const { logout, token } = useContext(AuthContext)
  const { showToast } = useContext(ToastContext)
  const { push } = useHistory()

  useEffect(() => {
    if (isSessionExpired()) {
      if (token) {
        showToast('negative', t('session-expired-please-login-again'), '', 20000)
      }
      logout().then(() => {
        push('/auth/login')
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPath])

  return <SessionValidatorContext.Provider value={{}}>{children}</SessionValidatorContext.Provider>
}
