import { Loading, ToastContext, t } from '@walter/shared-web'
import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import AuthContext from '../contexts/Auth'

export function ValidUserWrapper({ children }: { children: React.ReactElement }) {
  const { push } = useHistory()
  const { showToast } = React.useContext(ToastContext)

  const { logout, currentUser } = useContext(AuthContext)

  async function handleUnauthorize() {
    await logout()
    push('/auth/login')
  }

  const isAManager = React.useMemo(() => {
    return currentUser?.role === 'MANAGER' && currentUser?.managingCompany
  }, [currentUser])

  React.useEffect(() => {
    if (!isAManager) {
      showToast('negative', t('user-is-not-a-manager'))
      handleUnauthorize()
    }
  }, [isAManager])

  if (!isAManager) return <Loading />

  return children
}
