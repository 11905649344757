import './global.css'
import 'react-loading-skeleton/dist/skeleton.css'

import { ApolloProvider } from '@apollo/client'
import {
  ErrorBoundary,
  getLanguage,
  i18n,
  init,
  LastLocationProvider,
  MobileBanner,
  PopoverProvider,
  ToastProvider,
} from '@walter/shared-web'
import React from 'react'
import { I18nextProvider } from 'react-i18next'
import { AuthProvider } from './contexts/Auth'
import HotJarProvider from './contexts/HotJar'
import { IntercomProvider } from './contexts/Intercom'
import { MessageProvider } from './contexts/Message'
import Routes from './pages'
import { SkeletonTheme } from 'react-loading-skeleton'
import { Colors } from '@walter/shared'

const IS_FRENCH = getLanguage().toLowerCase().startsWith('fr')

const MOBILE_URLS = {
  iconImgUrl:
    'https://is4-ssl.mzstatic.com/image/thumb/Purple122/v4/f4/dd/e3/f4dde3dc-5065-315e-02fc-760f25778dbc/AppIcon-0-0-1x_U007emarketing-0-0-0-7-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/540x540bb.jpg',
  iosUrl: 'https://apps.apple.com/ca/app/usewalter-manager/id1450910401?itsct=apps_box_badge&amp;itscg=30200',
  androidUrl: `https://play.google.com/store/apps/details?id=com.walter.manager&hl=${IS_FRENCH ? 'fr' : 'en'}_CA&gl=CA`,
}

export default () => {
  return (
    <ErrorBoundary>
      <I18nextProvider i18n={i18n}>
        <ApolloProvider client={init()}>
          <ToastProvider>
            <PopoverProvider>
              <MessageProvider>
                <AuthProvider>
                  <LastLocationProvider>
                    <IntercomProvider>
                      <HotJarProvider>
                        <SkeletonTheme baseColor={Colors.white} highlightColor={Colors.offWhite}>
                          <Routes />
                          <MobileBanner {...MOBILE_URLS} />
                        </SkeletonTheme>
                      </HotJarProvider>
                    </IntercomProvider>
                  </LastLocationProvider>
                </AuthProvider>
              </MessageProvider>
            </PopoverProvider>
          </ToastProvider>
        </ApolloProvider>
      </I18nextProvider>
    </ErrorBoundary>
  )
}
