import { useManagerConversations, useManagerUnreadMessagesCount } from '@walter/shared'
import React from 'react'
import { useProjectId } from '../hooks/useProjectId'
import AppContext from './App'

type IConcersationsContext = ReturnType<typeof useManagerConversations> &
  ReturnType<typeof useManagerUnreadMessagesCount>

const ConversationsContext = React.createContext({} as IConcersationsContext)

export function ConversationsProvider({ children }: { children: React.ReactNode }) {
  const { currentManagingCompanyProjects } = React.useContext(AppContext)
  const projectId = useProjectId()

  const managerConversations = useManagerConversations({
    allowedProjects: currentManagingCompanyProjects as Parameters<typeof useManagerConversations>[0]['allowedProjects'],
    selectedProjectId: projectId === 'all' ? undefined : projectId,
  })

  const managerUnreadMessagesCount = useManagerUnreadMessagesCount(projectId === 'all' ? undefined : projectId)

  return (
    <ConversationsContext.Provider value={{ ...managerConversations, ...managerUnreadMessagesCount }}>
      {children}
    </ConversationsContext.Provider>
  )
}

export function useConversations() {
  return React.useContext(ConversationsContext)
}
