import { TASK_SORT_METHODS } from '../types/tasks'

export const sortTasks = <T extends { title?: string | null; createdAt?: string | number | null }[]>(
  tasks: T,
  method?: TASK_SORT_METHODS,
) => {
  switch (method) {
    case TASK_SORT_METHODS.CREATION_DATE_ASCENDING:
      return tasks.sort((a, b) => {
        if (!a.createdAt || !b.createdAt) return 0
        if (a.createdAt > b.createdAt) return 1
        if (a.createdAt < b.createdAt) return -1
        return 0
      })

    case TASK_SORT_METHODS.CREATION_DATE_DESCENDING:
      return tasks.sort((a, b) => {
        if (!a.createdAt || !b.createdAt) return 0
        if (a.createdAt > b.createdAt) return -1
        if (a.createdAt < b.createdAt) return 1
        return 0
      })

    case TASK_SORT_METHODS.ALPHABETICAL:
      return tasks.sort((a, b) => {
        if ((a.title ?? 'zz').toLowerCase() > (b.title ?? 'zz').toLowerCase()) return 1
        if ((a.title ?? 'zz').toLowerCase() < (b.title ?? 'zz').toLowerCase()) return -1
        return 0
      })

    default:
      return tasks
  }
}
