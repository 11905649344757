import { NetworkStatus } from '@apollo/client'
import { Api, SharedUtils } from '@walter/shared'
import { Form, UserUtils, convertToOptionValue, t, useDebouncedState } from '@walter/shared-web'
import { useAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import uniqBy from 'lodash/uniqBy'
import React, { useCallback, useEffect, useMemo } from 'react'
import { SelectorProps } from './types'
import { useSelectorProjectId } from './useSelectorProjectId'

type DefaultValue = { id: string; firstName?: string | null; lastName?: string | null }

type Props<Model, OptionValue> = SelectorProps<Model, OptionValue> & {
  defaultValue?: DefaultValue | DefaultValue[]
  matchFrom?: 'start' | 'any'
  withUnit?: boolean
}

export function ResidentFormSelector<Model, OptionValue>(props: Props<Model, OptionValue>) {
  const [textFilter, setTextFilter] = React.useState<string>('')
  const [debouncedTextFilter, forceUpdateDebouncedTextFilter] = useDebouncedState(textFilter, 750)
  const { fetchMoreResidents, residents, isLoading, hasMore } = useResidentsQuery<Model, OptionValue>(
    debouncedTextFilter,
    props,
  )

  const onAdditionalChange = useCallback(
    (selected: Parameters<NonNullable<typeof props.additionalOnChange>>[0]) => {
      if (textFilter) {
        forceUpdateDebouncedTextFilter('')
      }
      if (props?.additionalOnChange) {
        props.additionalOnChange(selected)
      }
    },
    [textFilter, props.additionalOnChange],
  )

  const defaultValue = useMemo(() => {
    return props?.defaultValue
      ? Array.isArray(props.defaultValue)
        ? convertToOptionValue(props.defaultValue, props.defaultValue, (user) => {
            const propertyApartment = user.property?.address?.apartmentNumber
            const propertiesApartments: string = user.properties
              ?.map((property: { address: { apartmentNumber: string } }) => property?.address?.apartmentNumber)
              .join(' - ')
            let propertiesString =
              propertyApartment && !propertiesApartments.includes(propertyApartment) ? ' - ' + propertyApartment : ''
            if (propertiesApartments) {
              propertiesString += ' - ' + propertiesApartments
            }
            let value = UserUtils.getUserName(user as DefaultValue)
            if (props.withUnit) {
              value += propertiesString
            }
            return value
          })
        : {
            value: { id: props.defaultValue.id },
            label: UserUtils.getUserName(props.defaultValue),
          }
      : undefined
  }, [props.defaultValue, residents])

  return (
    <Form.Select<Model, OptionValue>
      {...props}
      options={residents.map((resident) => {
        const propertyApartment = resident.property?.address?.apartmentNumber
        const propertiesApartments = resident.properties
          ?.map((property) => property?.address?.apartmentNumber)
          .join(' - ')
        let propertiesString =
          propertyApartment && !propertiesApartments.includes(propertyApartment) ? ' - ' + propertyApartment : ''
        if (propertiesApartments) {
          propertiesString += ' - ' + propertiesApartments
        }

        let label = UserUtils.getUserName(resident)
        if (props.withUnit) {
          label += propertiesString
        }

        return {
          value: { id: resident.id },
          label,
        }
      })}
      isLoading={isLoading || textFilter !== debouncedTextFilter}
      inputValue={hasMore || textFilter ? textFilter : undefined}
      onInputChange={hasMore || textFilter ? setTextFilter : undefined}
      additionalOnChange={onAdditionalChange}
      onMenuScrollToBottom={fetchMoreResidents}
      value={defaultValue}
      noOptionsText={t('no-residents')}
      matchFrom={props.matchFrom ?? 'start'}
    />
  )
}

type Resident = NonNullable<NonNullable<Api.ResidentsForSearchQuery['getResidentsForManager']['edges']>[number]['node']>

const projectIdsAtom = atomWithStorage<string[]>('projectIdsAtomResidentSelector', [])
export const accumulatedResidentsAtom = atomWithStorage<Resident[]>('accumulatedResidentsAtom', [])

function useResidentsQuery<Model, OptionValue>(textFilter: string, props: Props<Model, OptionValue>) {
  const projectId = useSelectorProjectId()
  const [projectIds, setProjectIds] = useAtom(projectIdsAtom)
  const [accumulated, setAccumulated] = useAtom(accumulatedResidentsAtom)

  const {
    data: { getResidentsForManager: { edges = [], pageInfo = {}, totalCount = 0 } = {} } = {},
    loading: loadingResidents,
    fetchMore,
    networkStatus,
  } = Api.useResidentsForSearchQuery({
    variables: {
      projectIds: props.projectIds ? props.projectIds : [projectId],
      first: 24,
      textFilter,
    },
    onCompleted(data) {
      const nodes = data.getResidentsForManager.edges?.map((edge) => edge.node) ?? []
      setAccumulated((prev) => {
        const defaultValue: Resident[] = SharedUtils.convertToArray(props.defaultValue)
        return uniqBy([...prev, ...defaultValue, ...nodes], 'id').filter((resident) => resident?.id?.length > 0)
      })
    },
  })

  useEffect(() => {
    const ids = props.projectIds ? props.projectIds : [projectId]
    if (JSON.stringify(ids) !== JSON.stringify(projectIds)) {
      setProjectIds(ids)
      setAccumulated(edges?.map((edge) => edge.node) ?? [])
    }
  }, [edges, props.projectIds?.length, projectId, projectIds])

  const fetchMoreResidents = useCallback(async () => {
    if ((edges?.length || 0) < totalCount && networkStatus !== NetworkStatus.setVariables) {
      try {
        await fetchMore({
          variables: {
            after: pageInfo.endCursor,
          },
        })
      } catch (err) {
        console.error('[ResidentSelector/useResidentsQuery]', 'Error fetching more', err)
      }
    }
  }, [pageInfo?.endCursor, edges?.length, totalCount])

  return {
    hasMore: (edges?.length || 0) < totalCount,
    residents: accumulated,
    fetchMoreResidents,
    isLoading:
      loadingResidents || networkStatus === NetworkStatus.fetchMore || networkStatus === NetworkStatus.setVariables,
  }
}
