import { Api } from '@walter/shared'
export * from './form'
/**
 * This file contains simple types to be used for data transformation
 * Because what we receive !+ what we have in forms != what we send to the backend
 */

export type Overwrite<T, U> = Pick<T, Exclude<keyof T, keyof U>> & U

export enum ReservationTypeEnum {
  SINGLE_DATE_TIME = 'SINGLE_DATE_TIME',
  SINGLE_DATE = 'SINGLE_DATE',
  MULTIPLE_DATE = 'MULTIPLE_DATE',
}

const AvailableReservations = ['SINGLE_DATE', 'MULTIPLE_DATE', 'SINGLE_DATE_TIME'] as const
export type ReservationType = typeof AvailableReservations[number]

export type Address = {
  id: string
  apartmentNumber?: string
  address1?: string
  zip?: string
  city?: string
  state?: string
  country?: string
}

export type AccessKey = {
  id: string
  number: string
}

export type Building = {
  id: string
}

export type Contact = {
  id: string
  email: string
  firstName?: string
  lastName?: string
  title?: string
  isPrivate?: boolean
  website?: string
  avatar?: File
}

export type FaqItem = {
  id: string
  question: string
  answer: string
  author?: User
  type: Api.Faq_Status
}

export type Locker = {
  id: string
  number: string
  cadastreNumber?: string
}

export type ManagingCompanyInformation = {
  id: string
  shortName: string | null
  longName: string | null
  email: string | null
  automaticChatMessage: string | null
  automaticChatMessageFr: string | null
  daysBeforeTaskAutoArchival: number | null
  autoArchiveTasks: boolean | null
  notifyTaskWhenMoved: boolean | null
  notifyTaskWhenFileAttached: boolean | null
  notifyTaskWhenCommentAdded: boolean | null
  notifyTaskWhenAssigned: boolean | null
  notifyTaskWhenLinked: boolean | null
  phone?: { extension: string | null; id: string | null; number: string | null } | null
  emergencyPhone?: { extension: string | null; id: string | null; number: string | null } | null
  address: {
    address1: string | null
    apartmentNumber: string | null
    city: string | null
    country: string | null
    countryCode: string | null
    id: string
    provinceCode: string | null
    state: string | null
    zip: string | null
  } | null
  logo: File | null
  logoAvatar: File | null
  syncServiceSettings: {
    id: string
    setting: { [x: string]: string }
    mappedService: { id: string; serviceName: string }
  }[]
}

export type ManagingCompanyRole = {
  id: string
  title: string
  description?: string | null
  permissions: Api.Permission_Type[]
  projects: { id: string; name?: string | null }[]
  shouldAutomaticallyAddNewBuildings: boolean
  canOnlySeeOwnTasks: boolean
}

export type Package = {
  id: string
  title: string
  status: Api.Package_Status
  description?: string
  image?: File
}

export type Parking = {
  id: string
  number: string
  cadastreNumber?: string
}

export type Phone = {
  id: string
  number?: string
  nationalFormat?: string
  extension?: string
}

export type Post = {
  id: string
  createdAt: any
  title: string | null
  type: Api.Post_Type
  description: string | null
  isDraft: boolean | null
  sendAsEmail: boolean | null
  sendAsAPP: boolean | null
  sendPostAt: any | null
  postSentAt: any | null
  sendAsEmailToNonAppUser: boolean | null
  assemblyLocation: string | null
  assemblyTime: string | null
  assemblyDate: any | null
  attachments: File[]
  coverImage: { id: string; url: string } | string
  signedProxies: File[]
}

export type Project = {
  id: string
  isDeleted?: boolean
  name: string
  nameInitials?: string
  tools?: Api.Tools[]
  timezone?: string
  residentCanCreateTask?: boolean
  residentCanEditFirstName?: boolean
  residentCanEditLastName?: boolean
  residentCanEditEmail?: boolean
  residentCanEditPhoneNumber?: boolean
  isCondoProject?: boolean
  isRentalProject?: boolean
  isInConstruction?: boolean
  colorHex?: string
  numberOfProperties?: number
  hasSecurityAgent?: boolean
  hasGuessRegistration?: boolean
  hasFobAccess?: boolean
  hasRestaurant?: boolean
  hasGym?: boolean
  hasShowerInGym?: boolean
  hasLockerChangingRoom?: boolean
  additionalInfo?: string
  hasGuessRoom?: boolean
  hasPartyRoom?: boolean
  allowDog?: boolean
  condoManagerId?: string
  needDogInArmInCommonArea?: boolean
  hasAccessForDog?: boolean
  accessForDogInfo?: string
  hasParkingForWorker?: boolean
  parkingForWorkerInfo?: string
  hasParkingForGuess?: boolean
  parkingForGuessInfo?: string
  hasValet?: boolean
  hasCarWash?: boolean
  carWashInfo?: string
  automaticallyInviteResidentCreatedFromThirdParty?: boolean
  servicesAvailable?: string[]
}

export type Property = {
  id: string
  proportionateShare: number
  copropertyDueAmount: number
  rentAmount: number
  rentStart: Date
  rentEnd: Date
  address?: {
    apartmentNumber: string
  }
}

export type Remote = {
  id: string
  number: string
}

export type Rule = {
  id: string
  createdAt?: string
  title: string
  description?: string
  category?: string
  isDeleted?: boolean
  projects?: Project[]
  attachments?: File[]
}
export type Reservation = {
  id: string
  start: string
  end: string
  status: Api.Reservation_Status
  note: string
  numberOfResidents: number
  amenity: { id: string; title: string }
  customer: Pick<User, 'id' | 'active' | 'firstName' | 'lastName' | 'hasInstalledApp' | 'email'>
}

export type Customer = {
  id: string
  firstName?: string
  lastName?: string
  active?: boolean
  hasInstalledApp?: boolean
  email?: string
  hasReceivedInvitation?: boolean
  cantDeliverEmail?: boolean
  cantDeliverEmailReason?: string
  cantDeliverEmailFullReason?: string
  preferedLanguage?: string
  phone?: Phone
  type?: string
  properties: Property[]
  property: Property
}

export type Segment = {
  id: string
  description: string
  isDeleted: boolean
  segmentFields: SegmentFields[]
  title: string
  titleEn: string
  titleFr: string
  descriptionEn: string
  descriptionFr: string
  users: User[]
}

export type SegmentFields = {
  comparison: Api.Comparison_Value
  id: string
  model: Api.Model
  path: string
  value: string
}

export type TaskStatus = {
  id: string
  title: string
  description?: string
  orderPosition?: number
  color?: string
  actAsComplete?: boolean
}

export type User = {
  id: string
  firstName?: string
  lastName?: string
  active?: boolean
  email?: string
  secondaryEmail?: string
  title?: string
  avatar?: File
  preferedLanguage?: string
  hasInstalledApp?: boolean
  hasReceivedInvitation?: boolean
  hasAppNotificationEnable?: boolean
  hasEmailNotificationEnable?: boolean
  cantDeliverEmail?: boolean
  cantDeliverEmailReason?: string
  cantDeliverEmailFullReason?: string
  note?: string
  type?: string
  officePhone?: Phone
  homePhone?: Phone
  phone?: Phone
  sendOnPressEnter?: boolean
  showArchivedOnly?: boolean
  password?: string
}

export type Resident = User & {
  role: Api.User_Role.Resident
}

export type Manager = User & { role: Api.User_Role.Manager }
