import { NetworkStatus } from '@apollo/client'
import { Api, SharedUtils } from '@walter/shared'
import { Form, UserUtils, convertToOptionValue, useDebouncedState } from '@walter/shared-web'
import { useAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import uniqBy from 'lodash/uniqBy'
import React, { useCallback, useEffect, useMemo } from 'react'
import AppContext from '../../contexts/App'
import AuthContext from '../../contexts/Auth'
import { SelectorProps } from './types'
import { useSelectorProjectId } from './useSelectorProjectId'

type DefaultValue = { id: string; firstName?: string; lastName?: string }

type Props<Model, OptionValue> = SelectorProps<Model, OptionValue> & {
  defaultValue?: DefaultValue | DefaultValue[]
}

export function ManagerFormSelector<Model, OptionValue>(props: Props<Model, OptionValue>) {
  const [textFilter, setTextFilter] = React.useState<string>('')
  const [debouncedTextFilter, forceUpdateDebouncedTextFilter] = useDebouncedState(textFilter, 750)
  const { fetchMoreManagers, managers, isLoading, hasMore } = useManagersQuery<Model, OptionValue>(
    debouncedTextFilter,
    props,
  )

  const onAdditionalChange = useCallback(
    (selected: Parameters<NonNullable<typeof props.additionalOnChange>>[0]) => {
      if (textFilter) {
        forceUpdateDebouncedTextFilter('')
      }
      if (props?.additionalOnChange) {
        props.additionalOnChange(selected)
      }
    },
    [textFilter, props.additionalOnChange],
  )

  const defaultValue = useMemo(() => {
    return props?.defaultValue
      ? Array.isArray(props.defaultValue)
        ? convertToOptionValue(props.defaultValue, props.defaultValue, (user) => {
            const manager = user as DefaultValue
            return UserUtils.getUserName(manager) ?? `${manager.firstName} ${manager.lastName}`
          })
        : {
            value: { id: props.defaultValue.id },
            label: UserUtils.getUserName(props.defaultValue),
          }
      : undefined
  }, [props.defaultValue])

  return (
    <Form.Select<Model, OptionValue>
      {...props}
      options={managers.map((manager) => {
        return { value: { id: manager.id }, label: UserUtils.getUserName(manager) }
      })}
      isLoading={isLoading || textFilter !== debouncedTextFilter}
      inputValue={hasMore || textFilter ? textFilter : undefined}
      onInputChange={hasMore || textFilter ? setTextFilter : undefined}
      additionalOnChange={onAdditionalChange}
      onMenuScrollToBottom={fetchMoreManagers}
      value={defaultValue}
    />
  )
}

type Manager = NonNullable<Api.ManagersQuery['getManagers']['edges']>[number]['node']

const projectIdsAtom = atomWithStorage<string[]>('projectIdsAtomManageSelector', [])
export const accumulatedManagersAtom = atomWithStorage<Manager[]>('accumulatedManagersAtom', [])

function useManagersQuery<Model, OptionValue>(textFilter: string, props: Props<Model, OptionValue>) {
  const { isAllProjects, currentManagingCompanyProjectIds } = React.useContext(AppContext)
  const { currentUser } = React.useContext(AuthContext)
  const projectId = useSelectorProjectId()
  const [projectIds, setProjectIds] = useAtom(projectIdsAtom)
  const [accumulated, setAccumulated] = useAtom(accumulatedManagersAtom)

  const {
    data: { getManagers: { edges = [], pageInfo = {}, totalCount = 0 } = {} } = {},
    networkStatus,
    loading: loadingManagers,
    fetchMore,
  } = Api.useManagersQuery({
    notifyOnNetworkStatusChange: true,
    skip: !currentManagingCompanyProjectIds?.length || !currentUser?.managingCompany?.id,
    variables: {
      first: 24,
      managingCompanyId: currentUser?.managingCompany?.id as string,
      projectIds: projectId != 'all' ? [projectId] : currentManagingCompanyProjectIds,
    },
    onCompleted(data) {
      const nodes = data.getManagers.edges?.map((edge) => edge.node) ?? []
      setAccumulated((prev) => {
        const defaultValue: Manager[] = SharedUtils.convertToArray(props.defaultValue)
        return uniqBy([...prev, ...defaultValue, ...nodes], 'id').filter((manager) => manager?.id?.length > 0)
      })
    },
  })

  useEffect(() => {
    const ids = isAllProjects ? currentManagingCompanyProjectIds : [projectId]
    if (JSON.stringify(ids) !== JSON.stringify(projectIds)) {
      setProjectIds(ids)
      setAccumulated(edges?.map((edge) => edge.node) ?? [])
    }
  }, [edges, isAllProjects, currentManagingCompanyProjectIds, projectId, projectIds])

  const fetchMoreManagers = useCallback(async () => {
    if ((edges?.length || 0) < totalCount && networkStatus !== NetworkStatus.setVariables) {
      try {
        await fetchMore({
          variables: {
            after: pageInfo.endCursor,
          },
        })
      } catch (err) {
        console.error('[ManagerSelector/useManagersQuery]', 'Error fetching more', err)
      }
    }
  }, [pageInfo?.endCursor, edges?.length, totalCount])

  return {
    hasMore: (edges?.length || 0) < totalCount,
    managers: accumulated,
    fetchMoreManagers,
    isLoading:
      loadingManagers || networkStatus === NetworkStatus.fetchMore || networkStatus === NetworkStatus.setVariables,
  }
}
