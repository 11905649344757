import { isNotAuthorizedError, RolePermissions } from '@walter/shared'
import { DashboardWrapperInner, GlobalLayout, LastLocationContext, Loading, t, ToastContext } from '@walter/shared-web'
import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import Menu from '../components/nav/Menu'
import Nav from '../components/nav/Nav'
import { AppProvider } from '../contexts/App'
import AuthContext from '../contexts/Auth'
import { ConversationsProvider } from '../contexts/Conversations'
import { CurrentUserProvider } from '../contexts/CurrentUser'
import { ManagingCompanyProvider } from '../contexts/ManagingCompany'
import { NotificationProvider } from '../contexts/Notifications'
import { PendingActionsProvider } from '../contexts/PendingActions'
import { SessionValidatorProvider } from '../contexts/SessionValidator'
import { ValidUserWrapper } from './ValidUserWrapper'

const ROUTE_TO_MODULE = {
  chat: 'CHAT',
  tasks: 'TASK',
  news: 'NEWS',
  calendar: 'CALENDAR',
  reservations: 'RESERVATIONS',
  marketplace: 'SALE',
  packages: 'MAIL',
  payments: 'SEGMENT',
  store: 'PROJECT',
  residents: 'USER',
  units: 'UNITS',
  files: 'FILE',
  amenities: 'AMENITY',
  contacts: 'CONTACT',
  rules: 'RULE',
  faq: 'PROJECT',
  groups: 'SEGMENT',
  settings: 'PROJECT',
} as const

export default function DashboardWrapper({ children }: { children: React.ReactNode }) {
  const {
    push,
    location: { pathname },
  } = useHistory()
  const { showToast } = useContext(ToastContext)
  const subdirectory = pathname.substring(pathname.lastIndexOf('/') + 1)

  const { lastLocation, setLastLocation } = useContext(LastLocationContext)
  const { token, logout, currentUser, errorCurrentUserQuery, loadingCurrentUserQuery } = useContext(AuthContext)

  if (Object.keys(ROUTE_TO_MODULE).indexOf(subdirectory) !== -1) {
    const shouldBeRedirected = !RolePermissions.userCanSeeModule(
      currentUser,
      ROUTE_TO_MODULE[subdirectory as keyof typeof ROUTE_TO_MODULE],
    )

    if (shouldBeRedirected) {
      push(pathname.substring(0, pathname.lastIndexOf('/')))
    }
  }

  async function handleUnauthorize() {
    showToast('negative', t('session-expired-please-login-again'), '', 20000)
    await logout()
    push('/auth/login')
  }

  React.useEffect(() => {
    if (!lastLocation) {
      setLastLocation(pathname)
    }
  }, [setLastLocation, lastLocation, pathname])

  React.useEffect(() => {
    if (isNotAuthorizedError(errorCurrentUserQuery) || !token) {
      if (token) {
        showToast('negative', t('session-expired-please-login-again'), '', 20000)
      }
      handleUnauthorize()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCurrentUserQuery])

  if (!currentUser || loadingCurrentUserQuery) return <Loading />

  return (
    <SessionValidatorProvider>
      <ValidUserWrapper>
        <CurrentUserProvider>
          <AppProvider>
            <ManagingCompanyProvider>
              <PendingActionsProvider>
                <NotificationProvider>
                  <ConversationsProvider>
                    <GlobalLayout>
                      <Nav />
                      <>
                        <Menu />
                        <DashboardWrapperInner>{children}</DashboardWrapperInner>
                      </>
                    </GlobalLayout>
                  </ConversationsProvider>
                </NotificationProvider>
              </PendingActionsProvider>
            </ManagingCompanyProvider>
          </AppProvider>
        </CurrentUserProvider>
      </ValidUserWrapper>
    </SessionValidatorProvider>
  )
}
