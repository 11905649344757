import { NetworkStatus } from '@apollo/client'
import { Api, SharedUtils } from '@walter/shared'
import { Form, convertToOptionValue, t, useDebouncedState } from '@walter/shared-web'
import { useAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import uniqBy from 'lodash/uniqBy'
import React, { useCallback, useEffect, useMemo } from 'react'
import { SelectorProps } from './types'
import { useSelectorProjectId } from './useSelectorProjectId'

type DefaultValue = { id: string; address?: { apartmentNumber?: string } }

type Props<Model, OptionValue> = SelectorProps<Model, OptionValue> & {
  defaultValue?: DefaultValue | DefaultValue[]
}

export function UnitSelector<Model, OptionValue>(props: Props<Model, OptionValue>) {
  const [textFilter, setTextFilter] = React.useState<string>('')
  const [debouncedTextFilter, forceUpdateDebouncedTextFilter] = useDebouncedState(textFilter, 750)
  const { fetchMoreUnits, units, isLoading, hasMore } = useUnitsQuery(debouncedTextFilter, props)

  const onAdditionalChange = useCallback(
    (selected: Parameters<NonNullable<typeof props.additionalOnChange>>[0]) => {
      if (textFilter) {
        forceUpdateDebouncedTextFilter('')
      }
      if (props?.additionalOnChange) {
        props.additionalOnChange(selected)
      }
    },
    [textFilter, props.additionalOnChange],
  )

  const defaultValue = useMemo(() => {
    return props?.defaultValue
      ? Array.isArray(props.defaultValue)
        ? convertToOptionValue(
            props.defaultValue,
            props.defaultValue,
            (unit) => (unit as DefaultValue)?.address?.apartmentNumber ?? t('unknown'),
          )
        : {
            value: { id: props.defaultValue.id },
            label: props.defaultValue?.address?.apartmentNumber ?? t('unknown'),
          }
      : undefined
  }, [props.defaultValue])

  return (
    <Form.Select<Model, OptionValue>
      {...props}
      options={units.map((unit) => {
        return {
          value: { id: unit.id },
          label: unit?.address?.apartmentNumber ?? '',
        }
      })}
      isLoading={isLoading || textFilter !== debouncedTextFilter}
      inputValue={hasMore || textFilter ? textFilter : undefined}
      onInputChange={hasMore || textFilter ? setTextFilter : undefined}
      additionalOnChange={onAdditionalChange}
      onMenuScrollToBottom={fetchMoreUnits}
      value={defaultValue}
      noOptionsText={t('no-units')}
    />
  )
}

type PropertyDetailed = NonNullable<Api.PropertiesDetailedPaginatedQuery['getProperties']['edges']>[number]['node']

const projectIdsAtom = atomWithStorage<string[]>('projectIdsAtomUnitSelector', [])
export const accumulatedUnitsAtom = atomWithStorage<PropertyDetailed[]>('accumulatedUnitsAtom', [])

function useUnitsQuery<Model, OptionValue>(textFilter: string, props: Props<Model, OptionValue>) {
  const projectId = useSelectorProjectId()
  const [projectIds, setProjectIds] = useAtom(projectIdsAtom)
  const [accumulated, setAccumulated] = useAtom(accumulatedUnitsAtom)

  const {
    data: { getProperties: { edges = [], pageInfo = {}, totalCount = 0 } = {} } = {},
    loading: loadingProperties,
    fetchMore,
    networkStatus,
  } = Api.usePropertiesDetailedPaginatedQuery({
    notifyOnNetworkStatusChange: true,
    skip: projectId === 'all' && !props.projectIds?.length,
    variables: {
      projectIds: props.projectIds ? props.projectIds : [projectId],
      first: 24,
      textFilter,
    },
    onCompleted(data) {
      const nodes = data.getProperties.edges?.map((edge) => edge.node) ?? []
      const defaultValue: PropertyDetailed[] = SharedUtils.convertToArray(props.defaultValue)
      setAccumulated((prev) => {
        return uniqBy([...prev, ...defaultValue, ...nodes], 'id').filter((unit) => unit?.id?.length > 0)
      })
    },
  })

  useEffect(() => {
    const ids = props.projectIds ? props.projectIds : [projectId]
    if (JSON.stringify(ids) !== JSON.stringify(projectIds)) {
      setProjectIds(ids)
      setAccumulated(edges?.map((edge) => edge.node) ?? [])
    }
  }, [edges, props.projectIds?.length, projectId, projectIds])

  const fetchMoreUnits = useCallback(async () => {
    if ((edges?.length || 0) < totalCount && networkStatus !== NetworkStatus.setVariables) {
      try {
        await fetchMore({
          variables: {
            after: pageInfo.endCursor,
          },
        })
      } catch (err) {
        console.error('[UnitSelector/useUnitsQuery]', 'Error fetching more', err)
      }
    }
  }, [pageInfo?.endCursor, edges?.length, totalCount])

  return {
    hasMore: (edges?.length || 0) < totalCount,
    units: accumulated,
    fetchMoreUnits,
    isLoading:
      loadingProperties || networkStatus === NetworkStatus.fetchMore || networkStatus === NetworkStatus.setVariables,
  }
}
