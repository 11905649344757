import { NetworkStatus } from '@apollo/client'
import { Api, SharedUtils, Types } from '@walter/shared'
import { Form, convertToOptionValue, t, useDebouncedState } from '@walter/shared-web'
import { useAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import uniqBy from 'lodash/uniqBy'
import React, { useCallback, useEffect, useMemo } from 'react'
import { SelectorProps } from './types'
import { useSelectorProjectId } from './useSelectorProjectId'
import AuthContext from '../../contexts/Auth'

type DefaultValue = { id: string; title: string }

type Props<Model, OptionValue> = SelectorProps<Model, OptionValue> & {
  defaultValue?: DefaultValue | DefaultValue[]
}

export function SegmentFormSelector<Model, OptionValue>(props: Props<Model, OptionValue>) {
  const { currentUser } = React.useContext(AuthContext)
  const [textFilter, setTextFilter] = React.useState<string>('')
  const [debouncedTextFilter, forceUpdateDebouncedTextFilter] = useDebouncedState(textFilter, 750)
  const { fetchMoreSegments, segments, isLoading, hasMore } = useSegmentsQuery(debouncedTextFilter, props)

  const onAdditionalChange = useCallback(
    (selected: Parameters<NonNullable<typeof props.additionalOnChange>>[0]) => {
      if (textFilter) {
        forceUpdateDebouncedTextFilter('')
      }
      if (props?.additionalOnChange) {
        props.additionalOnChange(selected)
      }
    },
    [textFilter, props.additionalOnChange],
  )

  const defaultValue = useMemo(() => {
    return props?.defaultValue
      ? Array.isArray(props.defaultValue)
        ? convertToOptionValue(props.defaultValue, props.defaultValue, (segment) => (segment as DefaultValue).title)
        : {
            value: { id: props.defaultValue.id },
            label: props.defaultValue.title,
          }
      : undefined
  }, [props.defaultValue])

  const options = useMemo(() => {
    return segments.map((segment) => {
      const title = SharedUtils.getTitle(segment, currentUser?.preferedLanguage)
      return {
        value: { id: segment.id },
        label: title ?? '',
      } as Types.Option
    })
  }, [segments])

  return (
    <Form.Select<Model, OptionValue>
      {...props}
      options={options}
      isLoading={isLoading || textFilter !== debouncedTextFilter}
      inputValue={hasMore || textFilter ? textFilter : undefined}
      onInputChange={hasMore || textFilter ? setTextFilter : undefined}
      additionalOnChange={onAdditionalChange}
      onMenuScrollToBottom={fetchMoreSegments}
      value={defaultValue}
      noOptionsText={t('no-groups')}
    />
  )
}

type Segment = NonNullable<Api.SegmentsPaginatedQuery['getSegments']['edges']>[number]['node']

const projectIdsAtom = atomWithStorage<string[]>('projectIdsAtomSegmentSelector', [])
export const accumulatedSegmentsAtom = atomWithStorage<Segment[]>('accumulatedSegmentsAtom', [])

function useSegmentsQuery<Model, OptionValue>(textFilter: string, props: Props<Model, OptionValue>) {
  const projectId = useSelectorProjectId()
  const [projectIds, setProjectIds] = useAtom(projectIdsAtom)
  const [accumulated, setAccumulated] = useAtom(accumulatedSegmentsAtom)

  const {
    data: { getSegments: { edges = [], pageInfo = {}, totalCount = 0 } = {} } = {},
    loading: loadingSegments,
    fetchMore,
    networkStatus,
  } = Api.useSegmentsPaginatedQuery({
    notifyOnNetworkStatusChange: true,
    skip: projectId === 'all' && !props.projectIds,
    variables: {
      projectIds: props.projectIds ? props.projectIds : [projectId],
      first: 24,
      textFilter,
    },
    onCompleted(data) {
      const nodes = data.getSegments.edges?.map((edge) => edge.node) ?? []
      setAccumulated((prev) => {
        const defaultValue: Segment[] = SharedUtils.convertToArray(props.defaultValue)
        return uniqBy([...prev, ...defaultValue, ...nodes], 'id').filter((segment) => segment?.id?.length > 0)
      })
    },
  })

  useEffect(() => {
    const ids = props.projectIds ? props.projectIds : [projectId]
    if (JSON.stringify(ids) !== JSON.stringify(projectIds)) {
      setProjectIds(ids)
      setAccumulated(edges?.map((edge) => edge.node) ?? [])
    }
  }, [edges, props.projectIds?.length, projectId, projectIds])

  const fetchMoreSegments = useCallback(async () => {
    if ((edges?.length || 0) < totalCount && networkStatus !== NetworkStatus.setVariables) {
      try {
        await fetchMore({
          variables: {
            after: pageInfo.endCursor,
          },
        })
      } catch (err) {
        console.error('[SegmentSelector/useSegmentsQuery]', 'Error fetching more', err)
      }
    }
  }, [pageInfo?.endCursor, edges?.length, totalCount])

  return {
    hasMore: (edges?.length || 0) < totalCount,
    segments: accumulated,
    fetchMoreSegments,
    isLoading:
      loadingSegments || networkStatus === NetworkStatus.fetchMore || networkStatus === NetworkStatus.setVariables,
  }
}
