import { MessagingService, RolePermissions, useMessagingService } from '@walter/shared'
import { Loading } from '@walter/shared-web'
import React, { lazy, Suspense } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import AuthContext from '../contexts/Auth'
import DashboardWrapper from '../layouts/DashboardWrapper'
import { Config } from '@walter/shared'

const Home = lazy(() => import(/* webpackChunkName: "home" */ './Home'))
const Login = lazy(() => import(/* webpackChunkName: "login", webpackPrefetch: true */ './auth/Login'))
const Register = lazy(() => import(/* webpackChunkName: "register", webpackPrefetch: true */ './auth/Register'))
const ForgotPassword = lazy(
  () => import(/* webpackChunkName: "forgotPassword", webpackPrefetch: true */ './auth/ForgotPassword'),
)
const ResetPassword = lazy(
  () => import(/* webpackChunkName: "resetPassword", webpackPrefetch: true */ './auth/ResetPassword'),
)
const Chat = lazy(() => import(/* webpackChunkName: "chat", webpackPrefetch: true */ './Chat'))
const Tasks = lazy(() => import(/* webpackChunkName: "tasks", webpackPrefetch: true */ './Tasks'))
const Settings = lazy(() => import(/* webpackChunkName: "settings", webpackPrefetch: true */ './Settings'))
const News = lazy(() => import(/* webpackChunkName: "news", webpackPrefetch: true */ './News'))
const Calendar = lazy(() => import(/* webpackChunkName: "calendar", webpackPrefetch: true */ './Calendar'))
const Amenities = lazy(() => import(/* webpackChunkName: "amenities", webpackPrefetch: true */ './Amenities'))
const Reservations = lazy(() => import(/* webpackChunkName: "reservations", webpackPrefetch: true */ './Reservations'))
const Packages = lazy(() => import(/* webpackChunkName: "packages", webpackPrefetch: true */ './Packages'))
const Rules = lazy(() => import(/* webpackChunkName: "rules", webpackPrefetch: true */ './Rules'))
const Contacts = lazy(() => import(/* webpackChunkName: "contacts", webpackPrefetch: true */ './Contacts'))
const Files = lazy(() => import(/* webpackChunkName: "files", webpackPrefetch: true */ './Files'))
const Residents = lazy(() => import(/* webpackChunkName: "residents", webpackPrefetch: true */ './Residents'))
const MarketPlace = lazy(() => import(/* webpackChunkName: "marketPlace", webpackPrefetch: true */ './Marketplace'))
const Filters = lazy(() => import(/* webpackChunkName: "filters", webpackPrefetch: true */ './Segments'))
const Profile = lazy(() => import(/* webpackChunkName: "profile", webpackPrefetch: true */ './Profile'))
const ThirdParty = lazy(() => import(/* webpackChunkName: "condoManager", webpackPrefetch: true */ './ThirdParty'))
const NewProject = lazy(() => import(/* webpackChunkName: "newProject", webpackPrefetch: true */ './NewProject'))
const Units = lazy(() => import(/* webpackChunkName: "units", webpackPrefetch: true */ './Units'))
const Faq = lazy(() => import(/* webpackChunkName: "faq", webpackPrefetch: true */ './Faq'))
const OpenSourceAttributions = lazy(
  () => import(/* webpackChunkName: "openSourceAttributions", webpackPrefetch: true */ './OpenSourceAttributions'),
)
const TemporarilyUnavailablePage = lazy(
  () =>
    import(/* webpackChunkName: "TemporarilyUnavailablePage", webpackPrefetch: true */ './TemporarilyUnavailablePage'),
)

const messagingService = new MessagingService()

export default function Routes() {
  const { currentUser } = React.useContext(AuthContext)
  useMessagingService({
    messagingService,
    userId: currentUser?.id,
  })

  return (
    <Suspense fallback={<Loading />}>
      <Router>
        <NetworkHealthCheck />
        <Switch>
          <Route path="/auth/login" component={Login} />
          <Route path="/auth/register" component={Register} />
          <Route path="/auth/forgot-password" component={ForgotPassword} />
          <Route path="/auth/reset-password" component={ResetPassword} />
          <Route path="/open-source-attribution" component={OpenSourceAttributions} />
          <Route path="/temporarily-unavailable" component={TemporarilyUnavailablePage} />
          <Route path="/:projectId?">
            <Switch>
              <DashboardWrapper>
                <Suspense fallback={<Loading type="page" />}>
                  <Switch>
                    {RolePermissions.userCanSeeModule(currentUser, 'PROJECT') && (
                      <Route exact path="/newProject" component={NewProject} />
                    )}
                    {RolePermissions.userCanSeeModule(currentUser, 'CHAT') && (
                      <Route path="/:projectId/chat" component={Chat} />
                    )}
                    {RolePermissions.userCanSeeModule(currentUser, 'TASK') && (
                      <Route path="/:projectId/tasks" component={Tasks} />
                    )}
                    {RolePermissions.userCanSeeModule(currentUser, 'PROJECT') && (
                      <Route path={['/:projectId/settings', '/:projectId/projectSettings']} component={Settings} />
                    )}
                    {RolePermissions.userCanSeeModule(currentUser, 'NEWS') && (
                      <Route path="/:projectId/news" component={News} />
                    )}
                    {RolePermissions.userCanSeeModule(currentUser, 'CALENDAR') && (
                      <Route path="/:projectId/calendar" component={Calendar} />
                    )}
                    {RolePermissions.userCanSeeModule(currentUser, 'AMENITY') && (
                      <Route path="/:projectId/amenities" component={Amenities} />
                    )}
                    {RolePermissions.userCanSeeModule(currentUser, 'RESERVATIONS') && (
                      <Route path="/:projectId/reservations" component={Reservations} />
                    )}
                    <Route path="/:projectId/packages" component={Packages} />
                    {RolePermissions.userCanSeeModule(currentUser, 'RULE') && (
                      <Route path="/:projectId/rules" component={Rules} />
                    )}
                    {RolePermissions.userCanSeeModule(currentUser, 'CONTACT') && (
                      <Route path="/:projectId/contacts" component={Contacts} />
                    )}
                    {RolePermissions.userCanSeeModule(currentUser, 'FILE') && (
                      <Route path="/:projectId/files/:folderId?" component={Files} />
                    )}
                    {RolePermissions.userCanSeeModule(currentUser, 'UNITS') && (
                      <Route path="/:projectId/units" component={Units} />
                    )}
                    {RolePermissions.userCanSeeModule(currentUser, 'USER') && (
                      <Route path="/:projectId/residents" component={Residents} />
                    )}
                    {RolePermissions.userCanSeeModule(currentUser, 'SEGMENT') && (
                      <Route path="/:projectId/groups" component={Filters} />
                    )}
                    <Route path="/:projectId/marketPlace" component={MarketPlace} />

                    <Route path="/:projectId/profile" component={Profile} />

                    {RolePermissions.userCanSeeModule(currentUser, 'THIRD_PARTY_SYNCHRONIZATION') && (
                      <Route path="/:projectId/thirdPartySetting" component={ThirdParty} />
                    )}
                    <Route path="/:projectId/components" component={Home} />
                    {RolePermissions.userCanSeeModule(currentUser, 'FAQ') && (
                      <Route path="/:projectId/faq" component={Faq} />
                    )}

                    <Route path="/:projectId" component={Home} />
                    <Route exact path="/" component={Home} />
                  </Switch>
                </Suspense>
              </DashboardWrapper>
            </Switch>
          </Route>
        </Switch>
      </Router>
    </Suspense>
  )
}

function NetworkHealthCheck() {
  const [hasNetworkError, setHasNetworkError] = React.useState(false)
  React.useEffect(() => {
    fetch(`${Config.restEndPoint}/healthz`).catch(setHasNetworkError)
  }, [])

  if (!hasNetworkError) return null

  return <Redirect to="/temporarily-unavailable" />
}
